import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Curriculum Vitae`}</h1>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
      mb: 2
    }} mdxType="Grid">  
    <Box mdxType="Box">
        <Heading variant="styles.h2" mdxType="Heading">Objective</Heading>
        <Text mdxType="Text">
            I want to build scalable infrastructure, work on 
            Distributed Systems, Cloud Infrastructure, and 
            contribute to building more secure systems.
        </Text>
    </Box>
    <Box mdxType="Box">
        <blockquote style={{
          "paddingLeft": "1em"
        }}>
            Location: New York, NY
            <br />
            <br />
            Email:    cybergenik at gmail dot com
        </blockquote>
    </Box>
    </Grid>
    <h2><a parentName="h2" {...{
        "href": "#favtech"
      }}>{`Preferred Tech`}</a>{`:`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Languages: Go, Rust, Python, C`}</p>
      <p parentName="blockquote">{`Tools: Linux, Docker, K8s, Git, Precommit, SaltStack, Github`}</p>
    </blockquote>
    <h2><a parentName="h2" {...{
        "href": "#profexp"
      }}>{`Professional Experience In`}</a>{`:`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Languages: Java, Go, Python, JS/TS (React, Angular, Express, Node)`}</p>
      <p parentName="blockquote">{`Tools: Linux, Docker, K8s, Git, Cypress, Precommit, GitLab (including CI), SaltStack`}</p>
    </blockquote>
    <h2><a parentName="h2" {...{
        "href": "#education"
      }}>{`Education`}</a></h2>
    <h3>{`Bachelors of Science in Computer Science - 3.8 `}<em parentName="h3">{`University of Utah`}</em></h3>
    <p><em parentName="p">{`2020 - May 2023`}</em></p>
    <ul>
      <li parentName="ul">{`Research Assistant, FLUX Research Group, Artifact Evaluation Committee - Fall 2021`}</li>
      <li parentName="ul">{`Teaching Assistant, Data Structures and Algorithms (CS 2420) - Fall 2020`}</li>
      <li parentName="ul">{`Lassonde 400 Entrepreneurship Recipient, merit scholarships 70% ride, HackTheU Finalist`}</li>
    </ul>
    <h3>{`Associates of Science in Computer Science and Information Systems - 4.0 `}<em parentName="h3">{`SLCC`}</em></h3>
    <p><em parentName="p">{`2018 - 2020`}</em></p>
    <ul>
      <li parentName="ul">{`Emphasis on Web Development & Linux Systems Engineering`}</li>
      <li parentName="ul">{`President's List, Dean's List, Phi Theta Kappa Honor Society`}</li>
      <li parentName="ul">{`Coding Club`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "#experience"
      }}>{`Experience`}</a></h2>
    <h3>{`Software Engineer - Palantir Technologies`}</h3>
    <p><em parentName="p">{`June 2023 - present`}</em></p>
    <ul>
      <li parentName="ul">{`infrastructure`}</li>
    </ul>
    <h3>{`Research Assisstant - FLUX Research Group`}</h3>
    <p><em parentName="p">{`Fall 2021 - May 2023`}</em></p>
    <ul>
      <li parentName="ul">{`Thesis: `}<a parentName="li" {...{
          "href": "https://github.com/Cybergenik/hopper"
        }}>{`Hopper`}</a>{` - Distributed Fuzzer`}</li>
      <li parentName="ul">{`Fuzzing WASM runtimes`}</li>
      <li parentName="ul">{`Static Analysis in Fuzzing`}</li>
    </ul>
    <h3>{`Software Development Engineer intern - Amazon AWS`}</h3>
    <p><em parentName="p">{`May 2021 - Aug 2021`}</em></p>
    <ul>
      <li parentName="ul">{`AWS::EC2::Security Trust Infrastructure (CA/PKI infrastructure)`}</li>
    </ul>
    <h3>{`Engineer Summer Analyst - Goldman Sachs`}</h3>
    <p><em parentName="p">{`May 2021 to Aug 2021`}</em></p>
    <ul>
      <li parentName="ul">{`PRX::Sparc Automation (internal deployment infra)`}</li>
    </ul>
    <h3>{`Software Engineer - `}<a parentName="h3" {...{
        "href": "https://blerp.com/"
      }}>{`Blerp`}</a></h3>
    <p><em parentName="p">{`Jun 2020 - Oct 2020`}</em></p>
    <ul>
      <li parentName="ul">{`Infrastructure | Search | Data Analytics`}</li>
    </ul>
    <h3>{`Data Science Engineer Intern - University of Utah Health`}</h3>
    <p><em parentName="p">{`Mar 2020 - Jun 2020`}</em></p>
    <ul>
      <li parentName="ul">{`Fullstack Webapp | Data Analytics`}</li>
    </ul>
    <h3>{`IT Operations Analyst - BaseCamp Franchising`}</h3>
    <p><em parentName="p">{`May 2019 - Mar 2020`}</em></p>
    <ul>
      <li parentName="ul">{`Infrastructure | Configuration Management | Virtualization`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "#proj"
      }}>{`Notable Projects`}</a></h2>
    <ul>
      <li parentName="ul">
        <h4 parentName="li">{`Distributed Fuzzer: `}<a parentName="h4" {...{
            "href": "https://github.com/Cybergenik/hopper"
          }}>{`Hopper`}</a></h4>
        <ul parentName="li">
          <li parentName="ul">{`Thesis project, written in Golang`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.flux.utah.edu/"
            }}>{`FLUX`}</a>{` Research group`}</li>
          <li parentName="ul">{`Inspired by AFL++`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <h4 parentName="li">{`WASM Runtime Fuzzer: Pointbreak`}</h4>
        <ul parentName="li">
          <li parentName="ul">{`Written in Python, GDB snapshot fuzzer`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.flux.utah.edu/"
            }}>{`FLUX`}</a>{` Research group`}</li>
          <li parentName="ul">{`Custom Mutation Engine inspired by HongFuzz`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <h4 parentName="li">{`Discord Bot: `}<a parentName="h4" {...{
            "href": "https://github.com/Cybergenik/Runemaster"
          }}>{`RuneMaster`}</a></h4>
        <ul parentName="li">
          <li parentName="ul">{`Featured on popular Bot website `}<a parentName="li" {...{
              "href": "https://top.gg/bot/713831642061602827"
            }}>{`top.gg`}</a></li>
          <li parentName="ul">{`Over `}<strong parentName="li">{`60+`}</strong>{` discord servers, `}<strong parentName="li">{`400`}</strong>{` average daily requests `}</li>
        </ul>
      </li>
      <li parentName="ul">
        <h4 parentName="li">{`ASCII Visualizer: `}<a parentName="h4" {...{
            "href": "https://github.com/Cybergenik/RR-vis"
          }}>{`RR-vis`}</a></h4>
        <ul parentName="li">
          <li parentName="ul">{`Written in Golang, cocnurrent model, TUI`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      